import {
  Box,
  CloseButton,
  Divider,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaFileMedical, FaUser } from "react-icons/fa";
import { NavItem } from "./NavItem";
import AuthContext from "../../context/auth/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const SidebarContent = ({ onClose, ...rest }) => {
  const { rol } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Box
          className="bg__login--img"
          onClick={handleGoHome}
          _hover={{ cursor: "pointer" }}
        />

        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>

      <Flex height={"100%"} direction={"column"} gap={1}>
        {rol.crear_examen && (
          <>
            <NavItem
              key={"mis-examenes"}
              icon={FaFileMedical}
              navigateTo="/mis_examenes"
            >
              Mis Exámenes
            </NavItem>

            <NavItem key={"perfil"} icon={FaUser} navigateTo="/perfil">
              Perfil
            </NavItem>
          </>
        )}

        <Divider borderWidth={1} mt={2} />

        <Text
          as="b"
          color={"gray.500"}
          alignSelf={"center"}
          justifyContent={"flex-end"}
          fontSize={"0.7rem"}
          mt={2}
        >
          Versión: 1.0
        </Text>
      </Flex>
    </Box>
  );
};

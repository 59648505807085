/* 
  contiene todas las paginas asociadas a la autentificacion 
*/

import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginScreen from "../screens/auth/LoginScreen";
import RegistroScreen from "../screens/auth/RegistroScreen";
import { PasswordResetScreen } from "../screens/auth/PasswordResetScreen";
import { CheckMail } from "../screens/auth/CheckMail";
import { EmailSent } from "../screens/auth/EmailSent";

const AuthRouter = () => {
  return (
    <div className="contenedor__auth--main">
      <div className="contenedor__auth--box">
        <Routes>
          <Route end path="/login" element={<LoginScreen />} />

          <Route end path="/auth/registro" element={<RegistroScreen />} />

          <Route
            end
            path="/forgot-password/:userId/:token"
            element={<PasswordResetScreen />}
          />
          <Route
            end
            path="/check-mail"
            element={<CheckMail />}
          /> 
          <Route
            end
            path="/mail-send"
            element={<EmailSent />}
          />                   

          {/* <Route path="*" element={<Navigate to="/auth/login" />} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default AuthRouter;

/* 
    maneja todas las funciones del authcontext que engloba todas las acciones de logueo o registro
*/

import React, { useReducer, useState } from "react";
import { useToast } from "@chakra-ui/react";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";
import { lanekApi } from "../../api/lanekApi";

const AuthState = ({ children }) => {
  const toast = useToast();
  const initialState = {
    usuario: JSON.parse(localStorage.getItem("persona")),
    rol: JSON.parse(localStorage.getItem("rol")),
    permisos: {},
    isAutenticado: Boolean(sessionStorage.getItem("autenticado")),
    isLoginLoading: false,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const [isLoading, setIsLoading] = useState(false);

  const startLogin = async (email, password) => {
    try {
      const { data } = await lanekApi.post("/auth/login/", {
        email: email.trim(),
        password: password.trim(),
      });

      localStorage.setItem("persona", JSON.stringify(data.user));
      localStorage.setItem("rol", JSON.stringify(data.user.rol));
      localStorage.setItem("token", data.token);

      dispatch({
        type: "LOGIN_SUCCESS",
        payload: data.user,
      });
    } catch (error) {
      dispatch({
        type: "LOGIN_LOADING",
        payload: false,
      });
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const startLoginGoogle = async (userData, password) => {
    const content = {
      email: userData.email,
      nombre: userData.given_name,
      apellidos: userData.family_name,
    };

    try {
      const { data } = await lanekApi.post("/auth/loginGoogle/", {
        ...content,
        password: password.trim(),
      });
      localStorage.setItem("persona", JSON.stringify(data.user));
      localStorage.setItem("rol", JSON.stringify(data.user.rol));
      localStorage.setItem("token", data.token);

      dispatch({
        type: "LOGIN_SUCCESS",
        payload: data.user,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "LOGIN_LOADING",
        payload: false,
      });
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const startRegister = async (userData) => {
    try {
      setIsLoading(true);
      const { data } = await lanekApi.post("/auth/registro/", userData);
      await lanekApi.post("/ficha", {
        paciente: `${userData.nombre} ${userData.apellidos}`,
        id_persona: data.persona.id,
      });

      await startLogin(userData.email, userData.contraseña);
      setIsLoading(false);

      toast({
        description: "Registro exitoso.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
      return true;
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const logoutRequest = async () => {
    dispatch({
      type: "LOGOUT",
      payload: [],
    });
    window.sessionStorage.clear();
    window.localStorage.clear();
  };

  const startLoading = (status) => {
    dispatch({
      type: "LOGIN_LOADING",
      payload: status,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        startLogin,
        startLoginGoogle,
        startRegister,
        logoutRequest,
        startLoading,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;

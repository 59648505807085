/* 
este componente es publico del login, si el usuario no esta autenticado lo mantiene en el login, si esta autenticado lo redirige al home page 
*/

import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/auth/AuthContext";

const PublicRouter = ({ children }) => {
  const { isAutenticado } = useContext(AuthContext);

  return isAutenticado ? <Navigate to="/" /> : children;
};

export default PublicRouter;

/* 
  Router principal, se maneja un router privado y publico dependiendo si el usuario esta autenticado o no.
*/

import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AuthRouter from "./AuthRouter";
import HomeRouter from "./HomeRouter";
import RegistroScreen from "../screens/auth/RegistroScreen";
import PublicRouter from "./PublicRouter";
import PrivateRouter from "./PrivateRouter";
import { ExamenState } from "../context/examen/ExamenState";
import { StoreState } from "../context/store/StoreState";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          element={
            <PrivateRouter>
              <StoreState>
                <ExamenState>
                  <HomeRouter />
                </ExamenState>
              </StoreState>
            </PrivateRouter>
          }
        />

        <Route
          path="/auth/*"
          element={
            <PublicRouter>
              <AuthRouter />
            </PublicRouter>
          }
        />

        <Route path="*" element={<Navigate to="/auth/login" replace />} />
        <Route
          path="/auth/registro"
          element={
            <PublicRouter>
              <RegistroScreen />
            </PublicRouter>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;

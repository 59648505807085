/* eslint-disable import/no-anonymous-default-export */
import {
  LISTA_ARCHIVOS,
  LISTA_ARCHIVOS_BLOB,
  CARGAR_EXAMENES_PACIENTE,
} from "./actions";

export default (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case LISTA_ARCHIVOS:
      return {
        ...state,
        archivos: payload,
      };

    case LISTA_ARCHIVOS_BLOB:
      return {
        ...state,
        archivosBlob: payload,
      };

    case CARGAR_EXAMENES_PACIENTE:
      return {
        ...state,
        examenes: payload,
      };

    default:
      return state;
  }
};

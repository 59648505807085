const InfoData = {
  ABMA: {

  },
  AVM: {
    header: {      
      titleBordePage: "INFORME FONOAUDIOLOGICO",        
      logo: "",  
    },
    antecedentesG: {
      oneTitle: "RESULTADOS PRE TERAPIA", 
      oneTitleTable: "Datos del Paciente",   
      cellNamePatient: "NOMBRE",
      patient: "Wualter Hernandez",
      cellDB: "FECHA DE NACIMIENTO",
      DB: "05/04/1993",
      cellAG: "EDAD",
      AG: "30",
      cellRut: "RUT",
      RUT: "27.152.241-0",
      cellGender: "GÉNERO",
      gender: "M",
      email: "007@gmail.com",
      cellPhone: "TELEFONO",
      phone: "+65 12345678",
      cellOccupation: "OCUPACIÓN",
      occupation: "ING",
      cellTalk: "DEMANDA VOCAL AL HABLAR",
      talk: "DATO",
      cellShout: "DEMANDA VOCAL AL GRITAR",
      shout: "DATO",
      cellSing: "DEMANDA VOCAL AL CANTAR",
      sing: "DATO",
      cellFB: "ANTECEDENTES FAMILIARES",
      FB: "DATO",
      cellDerivedBy: "DERIVADO POR",
      derivedBy: "DATO",
      cellDiagnostic: "DIAGNÓSTICO ORL",
      diagnostic: "DATO",
      cellExploration: "MÉTODO EXPLORACIÓN",
      exploration: "DATO",
      cellQM: "MÉTODO DE CONSULTA",
      QM: "DATO",
      cellRC: "MOTIVO DE CONSULTA",
      RC: "DATO",
      cellED: "FECHA DE EVALUACIÓN",
      ED: "DATO",
      cellEvaluator: "EVALUADOR",
      evaluator: "Jesus Fuentes",  
    },
    resultados: {
        histograma: {
          twoTitle: "II. VALORES PARÁMETROS ACÚSTICOS",
          oneSubTitle: "Señal completa",
          linkGrafico: "",
        },
        SPL: {
          threeTitle: "SPL (dB) Nivel de presión sonora",
          twoSubTitle: "Es la relación entre la presión sonora absoluta y un nivel de referencia de sonido en el aire.",
          cellIndicatorSPL: "Indicador",
          indicatorSPL: "SPL",
          cellHalfSPL: "Media",
          halfSPL: "58.721",
          cellRangeSPL: "Rango",
          rangeSPL: "55.092",
          linkGrafico: "",
        },
        F0:{
          fourTitle: "FRECUENCIA FUNDAMENTAL (F0 Hz)",
          threeSubTitle: "Corresponde al número de veces que vibran las cuerdas vocales por segundo.",
          cellIndicatorF0: "Indicador",
          indicatorF0: "F0",
          cellHalfF0: "Media",
          halfF0: "200.0",
          cellRangeF0: "Rango",
          rangeF0: "145.833",
          linkGrafico: "",
        },
        H1H2: { 
          fiveTitle: "H1-H2",
          fourSubTitle: "INDICA SOPLO EN CIERRE GLÓTICO, correlación entre fuente glótica y acelerómetro. Diferencia en dB entre magnitudes del primer y segundo armónico.",
          cellIndicatorH1H2: "Indicador",
          indicatorH1H2: "H1-H2",
          cellHalfH1H2: "Media",
          halfH1H2: "7.437",
          cellRangeH1H2: "Rango",
          rangeH1H2: "1.1 a 7.1",
          linkGrafico: "",
        },
        CPP: {
          sixTitle: "CPP (dB)",
          sixTitleCPP: "Cepstral peak prominence",
          fiveSubTitle: "Medida acústica más robusta y prometedora de la gravedad de la disfonía. Mientras más brillante sea la voz, más grande es el CPP (si la voz es soplada, áspera, ronca el CPP es más pequeño).",
          cellIndicatorCPP: "Indicador",
          indicatorCPP: "CPP",
          cellHalfCPP: "Media",
          halfCPP: "-14.269",
          cellRangeCPP: "Rango",
          rangeCPP: "21.6 a 23.8",
          linkGrafico: "",
        },
        jitter: {
          sevenTitle: "Jitter",
          sixSubTitle: "Variación de la frecuencia fundamental en cortos períodos de tiempo. Representa la “estabilidad de la fonación”.",          
          cellIndicatorJitter: "Indicador",
          indicatorJitter: "Jitter",
          cellHalfJitter: "Media",
          halfJitter: "0.267",
          cellRangeJitter: "Rango",
          rangeJitter: "1%",
          linkGrafico: "",
        },
        shimmer: {
          eightTitle: "Shimmer",
          sevenSubTitle: "Variación de la amplitud en cortos períodos de tiempo.",            
          cellIndicatorShimmer: "Indicador",
          indicatorShimmer: "Shimmer",
          cellHalfShimmer: "Media",
          halfShimmer: "0.008",
          cellRangeShimmer: "Rango",
          rangeShimmer: "0-3%",
        },
    },
    conclusiones: {
      nineTitle: "Resumen general",
      tenTitle: "V. DIAGNÓSTICO FONOAUDIOLÓGICO",  
      cellAP: "Parámetro acústico",
      cellMean: "Mean",
      meanSPL: "51.097",
      meanF0: "205.253",
      meanH1H2: "6.848",
      meanCPP: "-15.229",
      meanJitter: "0.267",
      meanShimmer: "0.008",
      cellSD: "Desviación estándar",
      SDSPL: "16.381",
      SDF0: "24.008",
      SDH1H2: "4.037",
      SDCPP: "5.256",
      SDJitter: "0.200",
      SDShimmer: "0.0522",
      cellMode: "Mode",
      modeSPL: "56.410",
      modeF0: "191.666",
      modeH1H2: "7.017",
      modeCPP: "-23.500",
      modeJitter: "0.0",
      modeShimmer: "0.0",
      cellRange2: "Range",
      range2SPL: "55.092",
      range2F0: "145.833",
      range2H1H2: "35.596",
      range2CPP: "26.057",
      range2Jitter: "1.030",
      range2Shimmer: "0.524",
      cellIndications: "INDICACIONES Y SUGERENCIAS",
      indications: "- Incorporar pautas de higiene vocal a su actividad cotidiana.",
      cellsignature: "FIRMA Y TIMBRE",
    },
    pageCake: {
      titlePagination: "LANEK", 
    }
  },
  OTRO: {

  },                                              
};
export default InfoData;
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { lanekApi } from "../../api/lanekApi";
import { LockIcon } from "@chakra-ui/icons";
import { FaExclamationCircle } from "react-icons/fa";

export const PasswordResetScreen = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);

  const id = useParams();
  const idUser = id.userId;
  const { token } = useParams();
  
  useEffect(() => {
    // Realiza una solicitud a la API REST para validar el token    
    lanekApi.get(`/auth/valid-token/${token}`)
      .then((response) => {
        const { data } = response;        
        if (data.valid) {
          setIsTokenValid(true);
        } else {
          setIsTokenValid(false);
        }
        setIsLoadingToken(false);
      })
      .catch((error) => {
        console.error("Error al validar el token:", error);
        setIsLoadingToken(false);
      });
  }, [token]);

  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      // Cuando Las contraseñas no coinciden
      toast({
        description: "Las contraseñas no coinciden.",
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    // Envía la nueva contraseña al servidor para cambiarla.
    try {
      setIsLoading(true);
      await lanekApi.post(`/auth/reset-password/${token}`, {
        idUser,
        password,
      });
      navigate("/auth/login", { replace: true });
      toast({
        description: "Contraseña actualizada con éxito.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      setIsTokenValid(false);
      console.error("Error al restablecer la contraseña:", error);
      toast({
        description: "La sesión para cambiar la contraseña ha expirado.",
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoadingToken ? (
        <>
          <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
            <Flex p={8} flex={1} align={"center"} justify={"center"}>
              <Stack mx={"auto"} w={"md"} maxW={"lg"} py={12} px={6}>
                <Box rounded={"lg"} boxShadow={"lg"} p={8}>
                  <Stack spacing={4}>
                    <Spinner />
                  </Stack>
                </Box>
              </Stack>
            </Flex>
          </Stack>
        </>
      ) : isTokenValid ? (
        <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
          <Flex p={8} flex={1} align={"center"} justify={"center"}>
            <Stack mx={"auto"} w={"md"} maxW={"lg"} py={12} px={6}>
              <Box rounded={"lg"} boxShadow={"lg"} p={8}>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>Nueva contraseña</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<LockIcon color="gray.300" />} // Icono de candado
                      />
                      <Input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Contraseña"
                        required
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Confirmar contraseña</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<LockIcon color="gray.300" />} // Icono de candado
                      />
                      <Input
                        type={showPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirmar Contraseña"
                        required
                      />
                    </InputGroup>
                  </FormControl>
                  <Button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    Mostrar Contraseña
                  </Button>
                  <FormControl></FormControl>
                  <Button onClick={handleResetPassword} isLoading={isLoading}>
                    Restablecer contraseña
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Flex>
        </Stack>
      ) : (
        <>
          <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
            <Flex p={8} flex={1} align={"center"} justify={"center"}>
              <Stack mx={"auto"} w={"md"} maxW={"lg"} py={12} px={6}>
                <Box rounded={"lg"} boxShadow={"lg"} p={8}>
                  <Stack spacing={4}>                    
                    <FaExclamationCircle size={36} color="#E53E3E" />
                    <Text fontSize="xl" fontWeight="bold" mt={4}>
                      La sesión ha caducado
                    </Text>
                    <Text fontSize="lg" mt={2}>
                      Por favor, genere nuevamente el correo para el cambio de
                      su contraseña.
                    </Text>
                  </Stack>
                </Box>
              </Stack>
            </Flex>
          </Stack>
        </>
      )}
    </>
  );
};

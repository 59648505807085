import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react";
import React from "react";

export const InputForm = ({ label, children, isRequired = false }) => {
  return (
    <FormControl isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      {children}
    </FormControl>
  );
};

export const InputWithSizeSm = ({
  value,
  handleBlur,
  handleChange,
  type,
  name,
  label,
  pattern,
}) => {
  return (
    <InputForm label={label}>
      <Input
        autoComplete="off"
        maxLength={1}
        borderColor="#a9a9a9"
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        type={type}
        value={value}
        width="3rem"
        pattern={pattern}
        _hover={{
          borderColor: "#a9a9a9",
        }}
        _focus={{ borderColor: "#262626" }}
      />
    </InputForm>
  );
};

export const InputWithSizeMd = ({
  value,
  handleBlur,
  handleChange,
  type,
  name,
  label,
}) => {
  return (
    <InputForm label={label}>
      <Input
        autoComplete="off"
        maxLength={12}
        borderColor="#a9a9a9"
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        type={type}
        value={value}
        _hover={{
          borderColor: "#a9a9a9",
        }}
        _focus={{ borderColor: "#262626" }}
      />
    </InputForm>
  );
};

export const InputRut = ({
  value,
  handleBlur,
  handleChange,
  type,
  name,
  label,
  isRequired,
}) => {
  return (
    <InputForm isRequired={isRequired} label={label}>
      <Input
        autoComplete="off"
        maxLength={12}
        borderColor="#a9a9a9"
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        type={type}
        value={value}
        _hover={{
          borderColor: "#a9a9a9",
        }}
        _focus={{ borderColor: "#262626" }}
      />
    </InputForm>
  );
};

export const InputSelect = ({
  handleChange,
  name,
  label,
  defaultValue,
  placeholder,
  isRequired = false,
}) => {
  return (
    <FormControl isRequired={isRequired} name={name} onChange={handleChange}>
      <FormLabel>{label}</FormLabel>
      <Select name={name} defaultValue={defaultValue} placeholder={placeholder}>
        <option value="masculino">Masculino</option>
        <option value="femenino">Femenino</option>
        <option value="otro">Otro</option>
      </Select>
    </FormControl>
  );
};

export const InputNormal = ({
  value,
  handleBlur,
  handleChange,
  type,
  name,
  label,
  isRequired,
}) => {
  return (
    <InputForm isRequired={isRequired} label={label}>
      <Input
        autoComplete="off"
        borderColor="#a9a9a9"
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        type={type}
        value={value}
        _hover={{
          borderColor: "#a9a9a9",
        }}
        _focus={{ borderColor: "#262626" }}
      />
    </InputForm>
  );
};

import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Flex, Heading, Stack } from "@chakra-ui/react";
import { ExamenContext } from "../context/examen/ExamenContext";
import { AvmScreen } from "./examenes/AvmScreen";
import { AbmaScreen } from "./examenes/AbmaScreen";
import { RenderCompletedExamAlert } from "../components/RenderAlert";
import { MakerScreen } from "./examenes/MakerScreen";
import AuthContext from "../context/auth/AuthContext";

export const ExamScreen = () => {
  const navigate = useNavigate();
  const {usuario} = useContext(AuthContext);
  const { createExam } = useContext(ExamenContext);
  const { producto, idexamen } = useParams();
  const [finishExamState, setFinishExamState] = useState(false);

  const handleCreate = async () => {
    setFinishExamState(true);
    await createExam(idexamen, producto, "Ambulatorio", usuario.paciente);
    setFinishExamState(false);
    navigate(`/mis_examenes`, { replace: true });
  };

  return (
    <>
      {finishExamState ? (
        <RenderCompletedExamAlert state={finishExamState} />
      ) : (
        <RenderCompletedExamAlert state={finishExamState} />
      )}

      <Container
        bg={"white"}
        shadow={"md"}
        rounded={"md"}
        maxW={"full"}
        py={8}
        color={"#525659"}
      >
        <Flex direction="column">
          <Stack direction={["column", "row"]}>
            <Flex direction={"column"} w={"full"} mb={8}>
              <Heading size="xl">{producto.toUpperCase()}</Heading>

              {producto === "abma" ? (
                <AbmaScreen handleCreate={handleCreate} />
              ) : producto === "avm" ? (
                <AvmScreen handleCreate={handleCreate} />
              ) : producto === "video" ? (
                <MakerScreen handleCreate={handleCreate} />
              ) : null}
            </Flex>
          </Stack>
        </Flex>
      </Container>
    </>
  );
};

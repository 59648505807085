/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Icon,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { FaAngleDown } from "react-icons/fa6";
import { LineChart } from "./LineChart";
import { ImageMaker } from "./ImageMaker";
import { TableAbmaData } from "./TableAbmaData";
import { AllLineChart } from "./AllLineChart";
import { HistogramInfo } from "./HistogramInfo";
import { BoxPlotDataTable } from "./BoxPlotDataTable";
import { ImagesAbma } from "./ImagesAbma";

export const Graficos = ({ datos, dataExamType, imagenes }) => {  
  // Array de features
  let resource_array = [];
  let arrayFeatures = [];
  if (datos) {
    if (dataExamType === "avm") {
      arrayFeatures.push("Resumen");      
    }
    if (dataExamType === "abma-lite") {
      arrayFeatures.push("Resumen");
      arrayFeatures.push("Imagenes");
    }
    resource_array = [{ ...datos }];
    Object.entries(resource_array).forEach((m) => {
      Object.entries(m[1].resources).forEach((l) => {
        Object.entries(l).forEach((s) => {
          if (
            s[1].title &&
            l[0] !== "Voices" &&
            l[0] !== "mesh" &&
            l[0] !== "candidate_rsc" &&
            l[0] !== "candidate_lcc" &&
            l[0] !== "candidate_rcc" &&
            l[0] !== "candidate_rmc" &&
            l[0] !== "candidate_lmc" &&
            l[0] !== "candidate_lsc"
          ) {
            arrayFeatures.push(l[1].title);
          }
        });
      });
    });
  }

  const [featuresSeleccionado] = useState("Resumen");
  const [featuresSeleccionadoVideo] = useState("EVM video");
  const [featuresSeleccionadoAbma] = useState("Resumen");

  // Estado para controlar la visualización del componente de texto aleatorio
  const [showRandomText, setShowRandomText] = useState(false);
  const [dataFeature, setFeature] = useState(null);

  useEffect(() => {
    if (dataExamType === "avm") {
      handleButtonClick(featuresSeleccionado);
    }
    if (dataExamType === "abma-lite") {
      handleButtonClickAbma(featuresSeleccionadoAbma);
    }
    if (dataExamType === "video") {
      handleButtonClickVideo(featuresSeleccionadoVideo);
    }
  }, [featuresSeleccionado]);

  const RandomTextComponent = ({ datos }) => {
    // funcion para identificar el features seleccionado
    if (dataFeature === "Resumen") {
      let resource_array = [];
      let fullData = [];
      if (datos) {
        resource_array = [{ ...datos }];
        Object.entries(resource_array).forEach((m) => {
          Object.entries(m[1].resources).forEach((l) => {
            if (dataExamType === "avm") {
              Object.entries(l).forEach((s) => {
                if (s[1].title && l[0] !== "Voices") {
                  fullData.push({
                    feature: l[0],
                    data: l[1].data,
                    title: l[1].title,
                    xaxis: l[1].xaxis,
                    xunit: l[1].xunit,
                    yaxis: l[1].yaxis,
                    yunit: l[1].yunit,
                  });
                }
              });
            }
            if (dataExamType === "abma-lite") {
              Object.entries(l).forEach((s) => {
                if (s[1].title && l[0] && l[0] !== "mesh") {
                  fullData.push({
                    feature: l[0],
                    data: l[1].data,
                    time: l[1].time,
                    title: l[1].title,
                    xaxis: l[1].xaxis,
                    xunit: l[1].xunit,
                    yaxis: l[1].yaxis,
                    yunit: l[1].yunit,
                  });
                }
              });
            }
          });
        });
      }

      return (
        <Box>
          <Heading my={10}>Resumen del examen en gráficos</Heading>
          <SimpleGrid columns={[1, 1, 2]} spacing={4}>
            {dataExamType === "avm" ? (
              <AllLineChart
                datos={datos}
                arrayFeatures={arrayFeatures}
                fullData={fullData}
                dataExamType={dataExamType}
              />
            ) : dataExamType === "abma-lite" ? (
              <AllLineChart
                datos={datos}
                arrayFeatures={arrayFeatures}
                fullData={fullData}
                dataExamType={dataExamType}
              />
            ) : null}
          </SimpleGrid>
        </Box>
      );
    }
    if (dataFeature === "Imagenes") {
      let resource_array = [];
      let fullData = [];
      if (datos) {
        resource_array = [{ ...datos }];
        Object.entries(resource_array).forEach((m) => {
          Object.entries(m[1].resources).forEach((l) => {            
            if (dataExamType === "abma-lite") {
              Object.entries(l).forEach((s) => {
                if (s[1].title && l[0] && l[0] !== "mesh") {
                  fullData.push({
                    feature: l[0],
                    data: l[1].data,
                    time: l[1].time,
                    title: l[1].title,                    
                  });
                }
              });
            }
          });
        });
      }

      return (
        <Box>
          <Heading my={10}>Resumen de imagenes</Heading>
          <SimpleGrid columns={[1, 1, 2]} spacing={4}>
            {dataExamType === "abma-lite" ? (
              <ImagesAbma
                datos={datos}
                arrayFeatures={arrayFeatures}
                fullData={fullData}
                imagenes={imagenes}
                dataExamType={dataExamType}
              />
            ) : null}
          </SimpleGrid>
        </Box>
      );
    } else {
      let resource_array = [];
      const descripcionF = [];
      const valorF = [];
      const stats = [];
      let fullData = [];
      let fullDataTime = [];
      let fullDataFormat = [];
      let fullDataFormatTime = [];
      let normativeValues = [];

      let images = " ";
      if (datos) {
        resource_array = [{ ...datos }];
        Object.entries(resource_array).forEach((m) => {
          Object.entries(m[1].resources).forEach((l) => {
            if (l[1].title === dataFeature) {
              if (dataExamType === "avm") {
                images = l[1].url;
                fullData.push(l[1].data);
                descripcionF.push(l[1]);
                Object.entries(l[1]?.stats).forEach((t) => {
                  stats.push(l[1]?.stats);
                  valorF.push(Number(t[1]).toFixed(2));
                });
              }
              if (dataExamType === "abma-lite" && l[1]?.norm) {
                images = l[1].url;
                fullData.push(l[1].data);              
                fullDataTime.push(l[1].time);
                descripcionF.push(l[1]);
                normativeValues.push(l[1].norm);
                Object.entries(l[1]?.stats).forEach((t) => {
                  stats.push(l[1]?.stats);
                  valorF.push(Number(t[1]).toFixed(2));
                });
              }
              if (dataExamType === "abma-lite") {
                images = l[1].url;
                fullData.push(l[1].data);              
                fullDataTime.push(l[1].time);
                descripcionF.push(l[1]);                
                Object.entries(l[1]?.stats).forEach((t) => {
                  stats.push(l[1]?.stats);
                  valorF.push(Number(t[1]).toFixed(2));
                });
              }              
              if (dataExamType === "video") {
                images = l[1].url;
                fullData.push(l[1].data);
                descripcionF.push(l[1]);
                Object.entries(l[1]?.stats).forEach((t) => {
                  stats.push(l[1]?.stats);
                  valorF.push(Number(t[1]).toFixed(2));
                });
              }
            }
          });
        });

        // Se obtienen todos los valores y se formatean
        if (!fullData) {
          if (fullData[0].length > 1) {
            fullDataFormat = fullData[0].map((numero) => {
              if (numero !== null && !isNaN(numero)) {
                return numero.toFixed(2);
              }
              return null;
            });
            fullData = fullDataFormat;
          }
        }

        if (dataExamType === "abma-lite") {
          if (fullDataTime[0].length > 1) {
            fullDataFormatTime = fullDataTime[0].map((numero) => {
              if (numero !== null && !isNaN(numero)) {
                return numero.toFixed(2);
              }
              return null;
            });
            fullDataTime = fullDataFormatTime;
          }
        }
      }

      return (
        <Box>
          {dataExamType === "avm" ? (
            <>
              <Box mt={4}>
                <LineChart
                  datos={datos}
                  dataFeature={dataFeature}
                  fullData={fullData}
                  descripcionF={descripcionF}
                />
              </Box>

              <Grid
                templateColumns={{ base: "1fr", lg: "3fr 2fr" }}
                gap={4}
                width="100%"
              >
                <Flex borderRadius="lg" borderWidth={1} boxShadow="md" p={4}>
                  <BoxPlotDataTable
                    dataFeature={dataFeature}
                    stats={stats}
                    fullData={fullData}
                  />
                </Flex>

                <HistogramInfo image={images} description={descripcionF} />
              </Grid>
            </>
          ) : dataExamType === "video" ? (
            <>
              <Box>
                <ImageMaker images={images} />
              </Box>
            </>
          ) : dataExamType === "abma-lite" ? (
            <>
              <Box mt={6}>
                <LineChart
                  datos={datos}
                  dataFeature={dataFeature}
                  fullData={fullData}
                  descripcionF={descripcionF}
                  fullDataTime={fullDataTime}
                  normativeValues={normativeValues}
                />
              </Box>
              <Box>
                <TableAbmaData stats={stats} />
              </Box>
            </>
          ) : null}
        </Box>
      );
    }
  };

  // Función que maneja el clic en el botón
  const handleButtonClick = (feature) => {
    if (
      feature === "CPP" ||
      feature === "F0" ||
      feature === "H1H2" ||
      feature === "SPECTROGRAM" ||
      feature === "HRF" ||
      feature === "JITTER" ||
      feature === "SHIMMER" ||
      feature === "Resumen" ||
      feature === "Nivel de Presion Sonora (SPL)" ||
      feature === "Frecuencia Fundamental (F0)" ||
      feature === "H1-H2" ||
      feature === "Factor de Riqueza Armonica (Brillo)" ||
      feature === "Jitter" ||
      feature === "Shimmer" ||
      feature === "Cepstral Peak Prominence (CPP)" ||
      feature === "SPL"
    ) {
      setShowRandomText(true);
      setFeature(feature);
    } else {
      setShowRandomText(false);
    }
  };

  const handleButtonClickVideo = (feature) => {
    if (
      feature === "EVM BPM" ||
      feature === "EVM Crop" ||
      feature === "EVM HSV" ||
      feature === "EVM Video" ||
      feature === "eyebrow_slope" ||
      feature === "eyes_area" ||
      feature === "face_slopes" ||
      feature === "leye_area" ||
      feature === "mesh" ||
      feature === "mouth_slope" ||
      feature === "reye_area" ||
      feature === "EVM video" ||
      feature === "EVM HSV variation" ||
      feature === "EVM BPM" ||
      feature === "eyes area" ||
      feature === "leye area" ||
      feature === "reye area" ||
      feature === "Slope comparison" ||
      feature === "Crop video" ||
      feature === "Speech Related Features" ||
      feature === "Slopes video" ||
      feature === "Left iris trajectory" ||
      feature === "Mouth slope comparison" ||
      feature === "Eyebrow slope comparison" ||
      feature === "srf"
    ) {
      setShowRandomText(true);
      setFeature(feature);
    } else {
      setShowRandomText(false);
    }
  };

  const handleButtonClickAbma = (feature) => {
    if (      
      feature === "Ángulo Cadera" ||
      feature === "Ángulo Torso" ||
      feature === "Ángulo Cadera izquierda" ||
      feature === "Ángulo Cadera derecha" ||
      feature === "Ángulo Rodilla Izquierda" ||
      feature === "Ángulo Rodilla derecha" ||
      feature === "Ángulo Pie izquierdo" ||
      feature === "Ángulo Pie derecho" ||
      feature === "Ángulo Codo izquierdo" ||
      feature === "Ángulo Codo Derecho" ||
      feature === "Ángulo Hombro izquierdo" ||
      feature === "Resumen" ||
      feature === "Imagenes" ||
      feature === "Ángulo Hombro derecho"
    ) {
      setShowRandomText(true);
      setFeature(feature);
    } else {
      setShowRandomText(false);
    }
  };

  return (
    <Box mt={6}>
      <Text as="em" fontSize="md">
        Selecciona la caracteristica que quieres visualizar
      </Text>

      {dataExamType === "avm" ? (
        <>
          <Wrap display={{ base: "none", md: "flex" }}>
            {arrayFeatures.map((feature, index) => (
              <WrapItem>
                <Button
                  key={index}
                  borderWidth={1}
                  boxShadow="md"
                  color={feature === dataFeature ? "white" : "black"}
                  colorScheme={feature === dataFeature ? "cyan" : "default"}
                  mr={2}
                  onClick={() => handleButtonClick(feature)}
                >
                  {feature}
                </Button>
              </WrapItem>
            ))}
          </Wrap>

          {/* Contenido Responsive */}
          <Flex
            justify="flex-start"
            align="center"
            direction="column"
            display={{ base: "block", md: "none" }}
            mt={2}
          >
            <Menu display={["none", "none", "flex"]}>
              <MenuButton
                as={Button}
                color="white"
                colorScheme="cyan"
                rightIcon={<Icon as={FaAngleDown} />}
              >
                {dataFeature ? dataFeature : "Features"}
              </MenuButton>
              <MenuList>
                {arrayFeatures.map((feature, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleButtonClick(feature)}
                    feature={feature}
                  >
                    {feature}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
          {showRandomText && <RandomTextComponent datos={datos} />}
        </>
      ) : dataExamType === "video" ? (
        <>
          <Wrap display={{ base: "none", md: "flex" }}>
            {arrayFeatures.map((feature, index) => (
              <WrapItem>
                <Button
                  key={index}
                  borderWidth={1}
                  boxShadow="md"
                  color={feature === dataFeature ? "white" : "black"}
                  colorScheme={feature === dataFeature ? "cyan" : "default"}
                  mr={2}
                  onClick={() => handleButtonClickVideo(feature)}
                >
                  {feature}
                </Button>
              </WrapItem>
            ))}
          </Wrap>

          {/* Contenido Responsive */}
          <Flex
            justify="flex-start"
            align="center"
            direction="column"
            display={{ base: "block", md: "none" }}
            mt={2}
          >
            <Menu display={["none", "none", "flex"]}>
              <MenuButton
                as={Button}
                color="white"
                colorScheme="cyan"
                rightIcon={<Icon as={FaAngleDown} />}
              >
                {dataFeature ? dataFeature : "Features"}
              </MenuButton>
              <MenuList>
                {arrayFeatures.map((feature, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleButtonClickVideo(feature)}
                    feature={feature}
                  >
                    {feature}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
          {showRandomText && <RandomTextComponent datos={datos} />}
        </>
      ) : dataExamType === "abma-lite" ? (
        <>
          <Wrap display={{ base: "none", md: "flex" }}>
            {arrayFeatures.map((feature, index) => (
              <WrapItem>
                <Button
                  key={index}
                  borderWidth={1}
                  boxShadow="md"
                  color={feature === dataFeature ? "white" : "black"}
                  colorScheme={feature === dataFeature ? "cyan" : "default"}
                  mr={2}
                  onClick={() => handleButtonClickAbma(feature)}
                >
                  {feature}
                </Button>
              </WrapItem>
            ))}
          </Wrap>
          {/* Contenido Responsive */}
          <Flex
            justify="flex-start"
            align="center"
            direction="column"
            display={{ base: "block", md: "none" }}
            mt={2}
          >
            <Menu display={["none", "none", "flex"]}>
              <MenuButton
                as={Button}
                color="white"
                colorScheme="cyan"
                rightIcon={<Icon as={FaAngleDown} />}
              >
                {dataFeature ? dataFeature : "Features"}
              </MenuButton>
              <MenuList>
                {arrayFeatures.map((feature, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleButtonClickAbma(feature)}
                    feature={feature}
                  >
                    {feature}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
          {showRandomText && <RandomTextComponent datos={datos} />}
        </>
      ) : null}
    </Box>
  );
};

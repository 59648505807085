import React, { useContext, useRef } from "react";
import { Button, Icon, Input } from "@chakra-ui/react";
import { RenderUploading } from "./RenderAlert";
import { ExamenContext } from "../context/examen/ExamenContext";

export const FileUploader = ({ placeHolder, accept, icon }) => {
  const {
    uploadedFiles,
    totalFilesUploaded,
    isUploading,
    setUploadedFilesList,
    setFilesBlobs,
  } = useContext(ExamenContext);

  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    const fileList = Array.from(event.target.files);

    //* Crear un arreglo de blobs a partir de los archivos seleccionados
    const fileBlobs = fileList.map((file) => {
      return URL.createObjectURL(file);
    });

    // Agregar los nuevos archivos a la lista existente
    const updatedFilesList = [...uploadedFiles, ...fileList];
    const updatedBlobsList = [...uploadedFiles, ...fileBlobs];

    setUploadedFilesList(updatedFilesList);
    setFilesBlobs(updatedBlobsList);

    fileInputRef.current.value = "";
  };

  return (
    <div>
      {isUploading ? (
        <RenderUploading
          state={isUploading}
          totalUploaded={totalFilesUploaded}
          listFiles={uploadedFiles}
        />
      ) : (
        <RenderUploading state={isUploading} />
      )}

      <Input
        ref={fileInputRef}
        id="fileInput"
        type="file"
        multiple
        style={{ display: "none" }}
        accept={accept}
        onChange={handleFileSelect}
      />

      <Button
        _hover={{ cursor: "pointer" }}
        as="label"
        boxShadow="md"
        htmlFor="fileInput"
        justifyContent="space-between"
        padding={8}
        colorScheme="twitter"
      >
        <Icon as={icon} mr={2} boxSize={5} />
        {placeHolder}
      </Button>
    </div>
  );
};

import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import AuthState from "./context/auth/AuthState";
import LanekApp from "./LanekApp";

import "./assets/css/App.css";

function App() {
  return (
    <ChakraProvider>
      <AuthState>
        <LanekApp />
      </AuthState>
    </ChakraProvider>
  );
}

export default App;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Icon,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import {
  FaChevronLeft,
  FaChevronRight,
  FaClipboardList,
  FaDownload,
} from "react-icons/fa";

import { ExamenContext } from "../../context/examen/ExamenContext";
import { lanekApi } from "../../api/lanekApi";

export const TablaExamenes = ({ idpaciente, paciente, setOpenDetalle }) => {
  const { getPatientExams, examenes } = useContext(ExamenContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ field: "", direction: "" });

  const examsPerPage = 5;

  useEffect(() => {
    getPatientExams(idpaciente);
    setSortConfig({ field: "createdAt", direction: "desc" }); // Establecer orden inicial
  }, [idpaciente]);

  const handleSort = (field) => {
    if (sortConfig.field === field) {
      setSortConfig((prevConfig) => ({
        field,
        direction: prevConfig.direction === "asc" ? "desc" : "asc",
      }));
    } else {
      setSortConfig({ field, direction: "asc" });
    }
  };

  const sortedExams = [...examenes].sort((a, b) => {
    const { field, direction } = sortConfig;
    if (a[field] < b[field]) return direction === "asc" ? -1 : 1;
    if (a[field] > b[field]) return direction === "asc" ? 1 : -1;
    return 0;
  });

  const totalExams = sortedExams.length;
  const totalPages = Math.ceil(totalExams / examsPerPage);

  const indexOfLastExam = currentPage * examsPerPage;
  const indexOfFirstExam = indexOfLastExam - examsPerPage;
  const currentExams = sortedExams.slice(indexOfFirstExam, indexOfLastExam);

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleDownloadFiles = async (idexamen) => {
    try {
      const response = await lanekApi.get(
        `/examen/download-files/${idexamen}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${paciente.nombre}_examen_${idexamen}.zip`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TableContainer mt={2} borderRadius={10} shadow={"md"}>
        <Table variant="simple" bg={"white"} borderColor={"black"}>
          <Thead bg="cyan.400">
            <Tr>
              <Th>#</Th>
              <Th>Tipo de examen</Th>
              <Th onClick={() => handleSort("estado_examen")}>Estado</Th>
              <Th onClick={() => handleSort("profesional")}>Tomado por</Th>
              <Th onClick={() => handleSort("createdAt")}>Fecha</Th>
              <Th>Acciones</Th>
            </Tr>
          </Thead>

          <Tbody>
            {currentExams.length > 0 ? (
              currentExams.map((examen, index) => (
                <Tr key={examen.id}>
                  <Td>{index + 1}</Td>
                  <Td>{examen.descripcion}</Td>
                  <Td>{examen.estado_examen}</Td>
                  <Td>{examen.autor}</Td>
                  <Td>{examen.fecha_creacion}</Td>
                  <Td>
                    {examen.estado_examen === "Procesado" && (
                      <>
                        <Tooltip hasArrow label="Ver resultados en línea">
                          <IconButton
                            fontSize="1.2rem"
                            variant={"ghost"}
                            aria-label="Ver en linea"
                            icon={<FaClipboardList />}
                            onClick={() => setOpenDetalle(examen.id)}
                          />
                        </Tooltip>

                        <Tooltip hasArrow label="Descargar archivos adjuntos">
                          <IconButton
                            fontSize="1.2rem"
                            variant={"ghost"}
                            aria-label="Descarga archivos"
                            icon={<FaDownload />}
                            onClick={() => handleDownloadFiles(examen.id)}
                          />
                        </Tooltip>
                      </>
                    )}
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={6}>No hay exámenes aún</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        {currentPage !== 1 && (
          <Icon
            _hover={{ cursor: "pointer" }}
            as={FaChevronLeft}
            bg={"gray.400"}
            boxSize={7}
            mr={2}
            onClick={goToPreviousPage}
            p={2}
            rounded={"full"}
          />
        )}

        {Array.from({ length: totalPages }).map((_, index) => (
          <Text
            key={index}
            as={"b"}
            fontSize={currentPage === index + 1 ? "2xl" : "sm"}
            color={currentPage === index + 1 ? "gray.600" : "gray.400"}
            mx={2}
          >
            {index + 1}
          </Text>
        ))}

        {currentExams.length === examsPerPage && (
          <Icon
            _hover={{ cursor: "pointer" }}
            as={FaChevronRight}
            bg={"gray.400"}
            boxSize={7}
            ml={2}
            onClick={goToNextPage}
            p={2}
            rounded={"full"}
          />
        )}
      </Box>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  Box,
  Card,
  CardBody,
  Divider,
  Heading,
  Link,
  ScaleFade,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaFileMedical } from "react-icons/fa";
// import { formatearFecha } from "../helpers/invertirFecha";
import { TablaExamenes } from "../components/Tablas/TablaExamenes";
import { DetalleExamen } from "../components/visuales/DetalleExamen";
import { ButtonWithIcon } from "../components/CustomButtons";
import { RenderLoading } from "../components/RenderAlert";
import AuthContext from "../context/auth/AuthContext";
import { StoreContext } from "../context/store/StoreContext";
import { SessionManager } from "../components/SessionManager";

const AlertInfo = () => {
  return (
    <Alert status="info" variant="top-accent" mt={4} rounded={"md"}>
      <AlertIcon />
      <Text>
        ¡Completa tu perfil y maximiza tu experiencia en nuestra app!
        <Link href="/perfil" ml={2} color={"blue.600"}>
          Ve a tu perfil
        </Link>
      </Text>
    </Alert>
  );
};

export const PersonScreen = () => {
  const navigate = useNavigate();
  const { usuario } = useContext(AuthContext);
  const { getPatient, paciente } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [openDetalleExamen, setOpenDetalleExamen] = useState(false);

  sessionStorage.setItem("paciente", usuario.paciente.id);

  const handleOpenDetalle = (idexam) => {
    sessionStorage.setItem("examen", idexam);
    setOpenDetalleExamen(!openDetalleExamen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await getPatient(usuario.paciente.id);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const ignoreKeys = ["id", "id_cuenta", "id_usuario"];
  const showInfo =
    !paciente.current ||
    Object.keys(paciente.current)
      .filter((key) => !ignoreKeys.some((ignoreKey) => key.includes(ignoreKey)))
      .some((key) => !paciente.current[key]);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <>
        <SessionManager />
        <ScaleFade initialScale={0.9} in={true}>
          <Card boxShadow="md">
            <CardBody>
              <Stack spacing="2">
                <Box display="flex" justifyContent="space-between">
                  <Heading size="md" textTransform="capitalize" fontSize="2xl">
                    {`${paciente?.nombre} ${paciente?.apellidos}`}
                  </Heading>
                </Box>

                <Divider opacity={1} />

                {paciente?.rut && (
                  <Text>
                    <strong>RUT: </strong>
                    {paciente.rut}
                  </Text>
                )}

                {paciente?.email && (
                  <Text>
                    <strong>Correo Electrónico: </strong> {paciente.email}
                  </Text>
                )}

                {paciente?.prevision && (
                  <Text>
                    <strong>Previsión: </strong> {paciente.prevision}
                  </Text>
                )}

                {paciente?.telefono && (
                  <Text>
                    <strong>Teléfono: </strong> {paciente.telefono}
                  </Text>
                )}

                {paciente?.fecha_nacimiento && (
                  <Text>
                    <strong>Fecha De Nacimiento: </strong>
                    {paciente.fecha_nacimiento}
                  </Text>
                )}

                {paciente?.sexo && (
                  <Text>
                    <strong>Género: </strong> {paciente.sexo}
                  </Text>
                )}
              </Stack>

              {showInfo && <AlertInfo />}
            </CardBody>
          </Card>

          {openDetalleExamen ? (
            <DetalleExamen setOpenDetalle={handleOpenDetalle} />
          ) : (
            <Box mt={6}>
              <ButtonWithIcon
                fn={() =>
                  navigate("/mis_examenes/nuevo_examen", { replace: true })
                }
                titleIcon={FaFileMedical}
                titleButton="Nuevo examen"
                colorScheme="cyan"
                color={"white"}
              />

              <TablaExamenes
                idpaciente={usuario.paciente.id}
                paciente={paciente}
                setOpenDetalle={handleOpenDetalle}
              />
            </Box>
          )}
        </ScaleFade>
        </>
      )}
    </>
  );
};

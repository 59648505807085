import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { Center } from "@chakra-ui/react";
import jwt_decode from "jwt-decode";

import AuthContext from "../../context/auth/AuthContext";

export const LoginGoogle = () => {
  const { startLoginGoogle, startLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  const password_google = "lanek2023";
  const google_client_id =
    "602868629600-k6aaggoma8lv940hlup08jg4nhaii6si.apps.googleusercontent.com";

  const handleLoginGoogle = (userData, passwordGoogle) => {
    startLoading(true);
    startLoginGoogle(userData, passwordGoogle);
    navigate("/perfil", { replace: true });
  };

  return (
    <GoogleOAuthProvider clientId={google_client_id}>
      <Center maxW={"full"}>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            const userData = jwt_decode(credentialResponse.credential);
            const passwordGoogle = password_google;
            handleLoginGoogle(userData, passwordGoogle);
          }}
          onError={() => {
            console.log("Error al hacer login con google");
          }}
        />
      </Center>
    </GoogleOAuthProvider>
  );
};

import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from "../../../utils/stylesDocumentPdf";

const ReporteAG = ({ info, pacienteData, data }) => (
    <View>    
        <>
        {data.general_title ? (<Text style={styles.titleBordePageCenter} >{data.general_title}
          {"\n"}
          {"\n"}          
        </Text>) : <Text style={styles.titleBordePageCenter} >{info.AVM.header.titleBordePage}
        {"\n"}
        {"\n"}          
      </Text>}                          
      </>
        <Text>                          
          <Text style={styles.oneTitle}>{info.AVM.antecedentesG.oneTitle}</Text>
          {"\n"}
          {"\n"}          
        </Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol100AG}>
              <Text style={styles.tableCellTitle}>{info.AVM.antecedentesG.oneTitleTable}</Text>
            </View>
          </View>  
          <View style={styles.tableRow}>
            <View style={styles.tableColAG}>
              <Text style={styles.tableCellBold}>{info.AVM.antecedentesG.cellNamePatient}</Text>                            
            </View>
            <View style={styles.tableCol75AG}>              
              <Text style={styles.tableCell}>{pacienteData.nombre} {pacienteData.apellidos}</Text>              
            </View>
          </View> 
          <View style={styles.tableRow}>
            <View style={styles.tableColAG}>
              <Text style={styles.tableCellBold}>{info.AVM.antecedentesG.cellDB}</Text>
            </View>
            <View style={styles.tableColAG}>              
              <Text style={styles.tableCell}>{pacienteData.fecha_nacimiento}</Text>              
            </View>
            <View style={styles.tableColAG}>
              <Text style={styles.tableCellBold}>{info.AVM.antecedentesG.cellAG}</Text>
            </View>
            <View style={styles.tableColAG}>
              <Text style={styles.tableCell}>{info.AVM.antecedentesG.AG}</Text>
            </View>
          </View> 
          <View style={styles.tableRow}>
            <View style={styles.tableColAG}>
              <Text style={styles.tableCellBold}>{info.AVM.antecedentesG.cellRut}</Text>
            </View>
            <View style={styles.tableColAG}>              
              <Text style={styles.tableCell}>{pacienteData.rut}</Text>     
            </View>
            <View style={styles.tableColAG}>
              <Text style={styles.tableCellBold}>{info.AVM.antecedentesG.cellGender}</Text>
            </View>
            <View style={styles.tableColAG}>              
              <Text style={styles.tableCell}>{pacienteData.sexo}</Text>              
            </View>
          </View> 
          <View style={styles.tableRow}>
            <View style={styles.tableColAG}>
              <Text style={styles.tableCellBold}>{info.AVM.antecedentesG.cellED}</Text>
            </View>
            <View style={styles.tableColAG}>
              <Text style={styles.tableCell}>{info.AVM.antecedentesG.ED}</Text>
            </View>
            <View style={styles.tableColAG}>
              <Text style={styles.tableCellBold}>{info.AVM.antecedentesG.cellEvaluator}</Text>
            </View>
            <View style={styles.tableColAG}>
              <Text style={styles.tableCell}>{info.AVM.antecedentesG.evaluator}</Text>
            </View>
          </View>                                                      
        </View>
        <Text style={styles.margenGraphicAG}></Text>
    </View>
);

export default ReporteAG;
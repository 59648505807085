import React from "react";
import { Box, Button, Flex, Heading, Stack, Text } from "@chakra-ui/react";

export const ExamOptionCard = ({
  description,
  disable,
  handle,
  heading,
  icon,
  value,
}) => {
  return (
    <Box
      maxW={{ base: "full" }}
      w={"full"}
      shadow={"md"}
      bg={"white"}
      rounded={"lg"}
      overflow="hidden"
      p={5}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Stack align={"start"} spacing={2} h={"100%"}>
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color={"white"}
          rounded={"full"}
          bg={"gray.500"}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="sm">{heading.toUpperCase()}</Heading>
          <Text mt={1} fontSize={"sm"}>
            {description}
          </Text>
        </Box>
      </Stack>

      <Box>
        <Button
          colorScheme={"blue"}
          isDisabled={disable}
          onClick={handle}
          size={"sm"}
          value={value}
          variant={"link"}
        >
          Seleccionar
        </Button>
      </Box>
    </Box>
  );
};

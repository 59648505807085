import React from "react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";

const BaseAlert = ({ blockScroll, children, state }) => {
  return (
    <Modal blockScrollOnMount={blockScroll} isOpen={state} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const RenderCompletedExamAlert = ({ state }) => {
  return (
    <BaseAlert blockScroll={false} state={state}>
      <Alert
        backgroundColor="transparent"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="lg"
        />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Finalizando examen...
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          Solo queda un poco más, por favor espere.
        </AlertDescription>
      </Alert>
    </BaseAlert>
  );
};

export const RenderUploading = ({ state, totalUploaded, listFiles }) => {
  return (
    <>
      <BaseAlert blockScroll={false} state={state}>
        <Alert
          backgroundColor="transparent"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
          />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Subiendo archivos...{" "}
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            {`${totalUploaded} de ${listFiles?.length} subidos, no cierre la ventana.`}
          </AlertDescription>
        </Alert>
      </BaseAlert>
    </>
  );
};

export const RenderLoading = ({ state }) => {
  return (
    <>
      <BaseAlert blockScroll={true} state={state}>
        <Alert
          backgroundColor="transparent"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
          />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Cargando información...
          </AlertTitle>
        </Alert>
      </BaseAlert>
    </>
  );
};

import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Center,  
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { lanekApi } from "../api/lanekApi";
import AuthContext from "../context/auth/AuthContext";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";

export const SessionManager = () => {    
  const { logoutRequest } = useContext(AuthContext);        
  const token = localStorage.getItem("token");
  const [tokenRefresh, setToken] = useState(null);

  const [isRenewalAlertOpen, setIsRenewalAlertOpen] = useState(false);
  const [counter, setCounter] = useState(60);

  useEffect(() => {      
    // Verifica si es necesario renovar el token
    const checkTokenExpiration = () => {        
      let NtokenRefresh = localStorage.getItem("token");

        const expirationThreshold = 1 * 60 * 1000; // 1 minuto en milisegundos
        const decodedToken = jwt_decode(NtokenRefresh);        

        if (
          token &&
          decodedToken.exp * 1000 - Date.now() < expirationThreshold
        ) {
          setIsRenewalAlertOpen(true);
          setCounter(60); // Reinicia el contador a 60 segundos

          const intervalId = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);

            if (counter === 0) {
              clearInterval(intervalId);
              setIsRenewalAlertOpen(false);
              localStorage.removeItem(token);
              logoutRequest();
              Swal.fire({
                icon: "error",
                title: "Sesión caducada.",
                text: "Iniciar sesión nuevamente!",
              });
            }
          }, 1000);
        }      
    };

    const intervalId = setInterval(checkTokenExpiration, 1000); // Verifica cada segundo
    return () => clearInterval(intervalId);
  }, []);

  const cerrarSession = () => {
    logoutRequest();
    Swal.fire({
      icon: "error",
      title: "Sesión caducada.",
      text: "Iniciar sesión nuevamente!",
    });
    setIsRenewalAlertOpen(false);
  };

  const generateNewToken = async () => {      
    try {
      const response = await lanekApi.post(`/auth/renovar-token/${token}`, {
        headers: { Authorization: token },
      });        
      const newToken = response.data.newToken;

      if (response.data) {
        setToken(newToken);        
        console.log(tokenRefresh);
        window.localStorage.setItem("token", newToken);

        setIsRenewalAlertOpen(false);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("Error al renovar el token", error);
    }
  };

  return (
    <>        
        <>
          <Box>
            {/* Mostrar el contador solo cuando el cuadro de diálogo está abierto */}
            <AlertDialog
              isOpen={isRenewalAlertOpen}
              leastDestructiveRef={undefined}
              onClose={() => setIsRenewalAlertOpen(false)}
              closeOnOverlayClick={false}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    <Center>¿Aún estás ahí?</Center>
                  </AlertDialogHeader>

                  {counter === 0 ? (
                    cerrarSession()
                  ) : (
                    <AlertDialogBody>
                      {/* <Center>
                        Tu sesión va finalizar en: {counter} segundos
                      </Center> */}
                      <Center>
                        Tu sesión va finalizar en 1 minuto.
                      </Center>
                    </AlertDialogBody>
                  )}

                  <AlertDialogFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={() => generateNewToken()}
                    >
                      Seguir conectado
                    </Button>
                    <Button variant="outline" onClick={() => cerrarSession()}>
                      Cerrar Sesión
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Box>            
        </>        
    </>
  );
};

/* eslint-disable import/no-anonymous-default-export */
import { CARGAR_PACIENTE } from "./actions";

export default (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case CARGAR_PACIENTE:
      return {
        ...state,
        paciente: payload,
      };

    default:
      return state;
  }
};

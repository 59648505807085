/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import { PacienteForm } from "../Forms/PacienteForm";
import { StoreContext } from "../../context/store/StoreContext";
import { formatearRut } from "../../utils/formatearRut";

export const UserInfoModal = ({ initialValues }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updatePatient } = useContext(StoreContext);

  const [rut, setRut] = useState(initialValues.rut);
  const [isLoading, setIsLoading] = useState(false);

  const handleRutChange = (event) => {
    const formattedRut = formatearRut(event.target.value);
    setRut(formattedRut);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = { ...values, rut };
    setIsLoading(true);
    await updatePatient(data);
    setSubmitting(false);
    setIsLoading(false);
    onClose();
  };

  return (
    <>
      <IconButton
        variant="ghost"
        colorScheme="blue"
        fontSize="20px"
        size={"sm"}
        aria-label="Edit profile"
        onClick={onOpen}
        icon={<FaEdit />}
      />

      <Modal isCentered scrollBehavior="inside" isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>Editar información</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PacienteForm
              handleRutChange={handleRutChange}
              initialValues={initialValues}
              isLoading={isLoading}
              onSubmit={handleSubmit}
              rut={rut}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

import React from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import HomeScreen from "../screens/HomeScreen";
import { ExamsOptionsScreen } from "../screens/ExamsOptionsScreen";
import { ExamScreen } from "../screens/ExamScreen";
import { PersonScreen } from "../screens/PersonScreen";
import { ProfileScreen } from "../screens/ProfileScreen";
import { Navbar } from "../components/navbar/Navbar";

/*
  Router para todas las vistas internas de la plataforma con su path correspondiente.
  Si se desea agregar una nueva vista a la aplicacion, se debe ingresar como una ruta aca primero
*/

const smMarginSize = "0";
const mdMarginSize = "15rem";

const HomeRouter = () => {
  const marginSize = useBreakpointValue({
    base: smMarginSize,
    md: mdMarginSize,
  });
  return (
    <Box>
      <Navbar>
        <Box p={4} marginLeft={marginSize} className="contenedor__home--main">
          <Routes>
            <Route path="/perfil" element={<ProfileScreen />} />
            <Route path="/" element={<HomeScreen />} />
            <Route path="/mis_examenes" element={<PersonScreen />} />

            <Route
              path="/mis_examenes/nuevo_examen"
              element={<ExamsOptionsScreen />}
            />
            <Route
              path="/mis_examenes/nuevo_examen/:producto/:idexamen"
              element={<ExamScreen />}
            />
          </Routes>
        </Box>
      </Navbar>
    </Box>
  );
};

export default HomeRouter;

import React, { useRef, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Box, Flex, useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import "../../../src/assets/css/App.css"; // Importa archivo de estilos CSS

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

export const LineChart = ({
  datos,
  dataFeature,
  fullData,
  descripcionF,
  fullDataTime,
  normativeValues
}) => {
  const chartRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  useEffect(() => {
    const updateContainerDimensions = () => {
      if (chartRef.current) {
        const newContainerWidth = chartRef.current.clientWidth;
        setContainerWidth(newContainerWidth);
      }
    };
    updateContainerDimensions();
    window.addEventListener("resize", updateContainerDimensions);

    return () => {
      window.removeEventListener("resize", updateContainerDimensions);
    };
  }, []);

  const adjustedWidth = containerWidth - 5;

  const titleVariant = useBreakpointValue({
    base: 18,
    md: 24,
  });

  const [isMdBreakpoint] = useMediaQuery("(min-width: 780px)");

  if (datos?.exam_type === "abma-lite" && normativeValues[0] !== undefined) {    
    let tiempoEtiquetas = fullDataTime;

    const configuracion = {
      responsive: true,
      plugins: {        
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: descripcionF[0].title,
          font: {
            size: titleVariant,
            fontWeight: "bold",
          },
        },
      },
      scales: {
        x: {
          type: "linear",          
          display: true,
          title: {
            display: true,
            text: descripcionF[0]?.xaxis + ` [${descripcionF[0]?.xunit}]`,
          },
        },
        y: {
          type: "linear",
          display: true,
          title: {
            display: true,
            text: descripcionF[0]?.yaxis + ` [${descripcionF[0]?.yunit}]`,
          },
        },
      },      
    };

    // Datos del gráfico
    const datosGrafico = {
      labels: tiempoEtiquetas,
      datasets: [
        {
          label: dataFeature,
          data: fullData[0],
          fill: false,
          borderColor: "rgba(75,192,192,1)",
        },
        {
          label: 'Valor Mínimo',
          // data: [105,105,105,105], // Rellena con el valor mínimo
          data: Array(fullData[0].length).fill(normativeValues[0].min),
          borderColor: 'green',
          fill: false,
          pointRadius: 0, // Establece el radio de los puntos a cero
          pointHoverRadius: 0, // Establece el radio de los puntos en hover a cero
          pointHitRadius: 0,
        },
        {
          label: 'Valor Máximo',
          data: Array(fullData[0].length).fill(normativeValues[0].max), // Rellena con el valor máximo
          borderColor: 'green',
          fill: false,
          pointRadius: 0, // Establece el radio de los puntos a cero
          pointHoverRadius: 0, // Establece el radio de los puntos en hover a cero
          pointHitRadius: 0,
        },
        // {
        //   label: 'Valor Avg',
        //   data: Array(fullData[0].length).fill(normativeValues[0].avg), // Rellena con el valor máximo
        //   borderColor: 'green',
        //   fill: false,
        //   pointRadius: 0, // Establece el radio de los puntos a cero
        //   pointHoverRadius: 0, // Establece el radio de los puntos en hover a cero
        //   pointHitRadius: 0,
        // },
      ],
    };

    return (
      <>
        <Flex
          ref={chartRef}
          borderRadius="md"
          borderWidth={1}
          boxShadow="md"
          height="object-fit"
          justify="center"
          mb={4}
          p={{ base: 0, md: 6 }}
          w="full"
        >
          {isMdBreakpoint ? (
            <Line
              width={adjustedWidth}
              height={500}
              data={datosGrafico}
              options={configuracion}
            />
          ) : (
            <Line
              width={adjustedWidth}
              height={150}
              data={datosGrafico}
              options={configuracion}
            />
          )}
        </Flex>
      </>
    );
  } 
  if (datos?.exam_type === "abma-lite") {    
    let tiempoEtiquetas = fullDataTime;

    const configuracion = {
      responsive: true,
      plugins: {        
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: descripcionF[0].title,
          font: {
            size: titleVariant,
            fontWeight: "bold",
          },
        },
      },
      scales: {
        x: {
          type: "linear",          
          display: true,
          title: {
            display: true,
            text: descripcionF[0]?.xaxis + ` [${descripcionF[0]?.xunit}]`,
          },
        },
        y: {
          type: "linear",
          display: true,
          title: {
            display: true,
            text: descripcionF[0]?.yaxis + ` [${descripcionF[0]?.yunit}]`,
          },
        },
      },      
    };

    // Datos del gráfico
    const datosGrafico = {
      labels: tiempoEtiquetas,
      datasets: [
        {
          label: dataFeature,
          data: fullData[0],
          fill: false,
          borderColor: "rgba(75,192,192,1)",
        },        
      ],
    };

    return (
      <>
        <Flex
          ref={chartRef}
          borderRadius="md"
          borderWidth={1}
          boxShadow="md"
          height="object-fit"
          justify="center"
          mb={4}
          p={{ base: 0, md: 6 }}
          w="full"
        >
          {isMdBreakpoint ? (
            <Line
              width={adjustedWidth}
              height={500}
              data={datosGrafico}
              options={configuracion}
            />
          ) : (
            <Line
              width={adjustedWidth}
              height={150}
              data={datosGrafico}
              options={configuracion}
            />
          )}
        </Flex>
      </>
    );
  }else {
    let labels = Array.from(
      { length: fullData[0].length },
      (_, index) => index * 1
    );
    const tiempoEtiquetas = labels.filter((valor, index) => index % 1 === 0);

    const configuracion = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: descripcionF[0].title,
          font: {
            size: 24,
            fontWeight: "bold",
          },
        },
      },
      scales: {
        x: {
          type: "linear",
          ticks: {
            stepSize: 100,
          },
          display: true,
          title: {
            display: true,
            text: descripcionF[0]?.xaxis + ` [${descripcionF[0]?.xunit}]`,
          },
        },
        y: {
          type: "linear",
          display: true,
          title: {
            display: true,
            text: descripcionF[0]?.yaxis + ` [${descripcionF[0]?.yunit}]`,
          },
        },
      },
    };

    // Datos del gráfico
    const datosGrafico = {
      labels: tiempoEtiquetas,
      datasets: [
        {
          label: dataFeature,
          data: fullData[0],
          fill: false,
          borderColor: "rgba(75,192,192,1)",
        },
      ],
    };

    return (
      <>
        <Box>
          <Box>
            <Line data={datosGrafico} options={configuracion} />
          </Box>
        </Box>
      </>
    );
  }
};

/* 
  maneja las rutas privadas de la aplicacion, como el login y el registro
*/

import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthContext from "../context/auth/AuthContext";

const PrivateRouter = ({ children }) => {
  const { pathname, search } = useLocation();
  const { isAutenticado } = useContext(AuthContext);

  sessionStorage.setItem("last-path", pathname + search);
  const url = window.location.pathname;

  url === "/"
    ? window.sessionStorage.setItem("autenticado", isAutenticado)
    : window.sessionStorage.setItem("autenticado", isAutenticado);

  return isAutenticado ? children : <Navigate to="/auth/login" />;
};

export default PrivateRouter;

import React from "react";
import { Box, Container, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { FaInfo } from "react-icons/fa";
import { UserInfoModal } from "./Modals/UserInfoModal";

export const ProfileInfoUser = ({ user }) => {
  return (
    <>
      {user && (
        <Container
          mt={12}
          fontSize={"lg"}
          color={"gray.600"}
          borderRightWidth={{ base: 0, md: 2 }}
          borderBottomWidth={{ base: 2, md: 0 }}
          pb={{ base: 4, md: 0 }}
        >
          <HStack mb={4} justify={"space-between"}>
            <Box>
              <Icon as={FaInfo} />
              <Text as={"b"} fontSize={"xl"}>
                Información básica
              </Text>
            </Box>

            <UserInfoModal initialValues={user} />
          </HStack>
          <Stack>
            <HStack>
              <Text as={"b"}>Nombre:</Text>
              <Text>{user.nombre}</Text>
            </HStack>

            <HStack>
              <Text as={"b"}>Apellidos:</Text>
              <Text>{user.apellidos}</Text>
            </HStack>

            <HStack>
              <Text as={"b"}>RUT:</Text>
              <Text>{user.rut}</Text>
            </HStack>

            <HStack>
              <Text as={"b"}>Correo electrónico:</Text>
              <Text>{user.email}</Text>
            </HStack>

            <HStack>
              <Text as={"b"}>Teléfono:</Text>
              <Text>{user.telefono}</Text>
            </HStack>

            <HStack>
              <Text as={"b"}>Fecha de nacimiento:</Text>
              <Text>
                {user.fecha_nacimiento?.split("-").reverse().join("-")}
              </Text>
            </HStack>

            <HStack>
              <Text as={"b"}>Previsión:</Text>
              <Text>{user.prevision}</Text>
            </HStack>

            <HStack>
              <Text as={"b"}>Sexo:</Text>
              <Text>{user.sexo}</Text>
            </HStack>

            <HStack>
              <Text as={"b"}>Estado Civil:</Text>
              <Text>{user.estado_civil}</Text>
            </HStack>
          </Stack>
        </Container>
      )}
    </>
  );
};

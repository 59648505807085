import React from "react";
import { ProfileContent } from "../components/ProfileContent";
import { Container } from "@chakra-ui/react";

export const ProfileScreen = () => {
  return (
    <>
      <Container maxW={"full"}>
        <ProfileContent />
      </Container>
    </>
  );
};

import { Flex, Icon, Link } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

export const NavItem = ({ icon, children, navigateTo, ...rest }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const paths = pathname.split("/");
  const firstPath = paths[1];

  return (
    <Link style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
      <Flex
        className={`/${firstPath}` === navigateTo && "button_navegacion--item"}
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        onClick={() => {
          navigate(`${navigateTo}`);
        }}
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

/* 
    maneja todas las funciones del store, peticiones referentes a pacientes, profesionales, cuentas, usuarios
*/

import React, { useContext, useReducer } from "react";
import { useToast } from "@chakra-ui/react";
import StoreReducer from "./StoreReducer";
import AuthContext from "../auth/AuthContext";
import { StoreContext } from "./StoreContext";
import { lanekApi } from "../../api/lanekApi";

export const StoreState = ({ children }) => {
  const toast = useToast();
  const { logoutRequest, usuario } = useContext(AuthContext);

  const initialState = {
    paciente: {},
  };

  const [state, dispatch] = useReducer(StoreReducer, initialState);

  /* *************************************** 
    METODOS DE PACIENTES
  ****************************************** */

  const getPatient = async (idpaciente) => {
    const url = `paciente/${idpaciente}`;
    try {
      const { data } = await lanekApi.get(url);
      dispatch({
        type: "CARGAR_PACIENTE",
        payload: data.item,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const crearPaciente = async (data, token) => {
    const url = "/persona";
    try {
      const persona = await lanekApi.post(
        url,
        { ...data, tipo_persona: "Paciente" },
        { headers: { Authorization: token } }
      );
      await lanekApi.post("/ficha", {
        paciente: `${data.nombre} ${data.apellidos}`,
        profesional: "Jesus Fuentes",
        id_persona: persona.data.persona.id,
      });

      toast({
        description: "Paciente creado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      logoutRequest();
      toast({
        description: error.response.data.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const updatePatient = async (data) => {
    const { id: idpaciente } = data;
    const url = `/paciente/${idpaciente}`;
    try {
      await lanekApi.put(url, { ...data });
      await getPatient(idpaciente);

      toast({
        description: "Paciente actualizado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  /* *************************************** 
    METODOS DE FEEDBACKS
  ****************************************** */

  const createFeedback = async (image, data) => {
    const url = "/feedback";

    const autor = usuario.paciente;
    const nombre_autor = autor.apellidos
      ? autor.nombre + " " + autor.apellidos
      : autor.nombre;

    const contentEmail = { ...data, autor: nombre_autor, email: autor.email };

    const formData = new FormData();
    formData.append("feedback", JSON.stringify(contentEmail));
    formData.append("image", image);
    try {
      await lanekApi.post(url, formData);

      toast({
        description: "Feedback creado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <StoreContext.Provider
      value={{
        paciente: state.paciente,
        getPatient,
        crearPaciente,
        updatePatient,
        createFeedback,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

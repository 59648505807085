import React, { useContext, useReducer, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { lanekApi } from "../../api/lanekApi";

import { ExamenContext } from "./ExamenContext";
import ExamenReducer from "./ExamenReducer";
import AuthContext from "../auth/AuthContext";
import { stageForWorker, urlForWorker } from "../../helpers/defaultValues";

export const ExamenState = ({ children }) => {
  const { usuario } = useContext(AuthContext);
  const toast = useToast();
  const initialState = {
    archivos: [],
    archivosBlob: [],
    examenes: [],
    result: [],
    tipo_examenes: [],
    isLoading: false,
  };

  const [isUploading, setIsUploading] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [totalFilesUploaded, setTotalFilesUploaded] = useState(0);
  const [uploadedPercent, setUploadedPercent] = useState({});

  const [state, dispatch] = useReducer(ExamenReducer, initialState);

  const setUploadedFilesList = (files) => {
    if (filesList.length === 0) {
    }
    setFilesList([...files]);
    dispatch({
      type: "LISTA_ARCHIVOS",
      payload: [...files],
    });

    setFilesBlobs(files);
  };

  const handleNewVideoFile = (file) => {
    if (filesList.length === 0) {
    }
    setFilesList([...file]);
    dispatch({
      type: "LISTA_ARCHIVOS",
      payload: [...file],
    });

    //* Filtrar los objetos válidos y obtener los Blobs de URLs
    const urlBlobs = file
      .filter(
        (fileURL) =>
          typeof fileURL === "object" && "blob" in fileURL && "name" in fileURL
      )
      .map((fileURL) => fileURL.blob);

    dispatch({
      type: "LISTA_ARCHIVOS_BLOB",
      payload: urlBlobs,
    });
  };

  const setFilesBlobs = (fileURLs) => {
    // Crear objetos Blob a partir de las URLs de objeto
    const blobs = fileURLs.map((fileURL) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", fileURL, true);
      xhr.responseType = "blob";

      return new Promise((resolve, reject) => {
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(new Error(`Failed to fetch Blob from URL: ${fileURL}`));
          }
        };
        xhr.onerror = () => {
          reject(new Error(`Failed to fetch Blob from URL: ${fileURL}`));
        };
        xhr.send();
      });
    });

    // Esperar a que todas las promesas se resuelvan
    Promise.all(blobs)
      .then((resolvedBlobs) => {
        dispatch({
          type: "LISTA_ARCHIVOS_BLOB",
          payload: resolvedBlobs,
        });
      })
      .catch((error) => {
        console.error("Error al obtener Blobs:", error);
        // Manejar el error según sea necesario
      });
  };

  const setUploadedProgress = (data) => {
    setUploadedPercent(data);
  };

  //recibe una lista de archivos el cual seran subidos al bucket
  const handleUploadFilesToBucket = async (idexamen) => {
    setIsUploading(true);
    await uploadNextFile(0, idexamen);
  };

  const uploadNextFile = async (index, idexamen) => {
    if (index >= filesList.length) {
      setIsUploading(false);
      return;
    }

    const file = filesList[index];
    await uploadFile(file, index, idexamen);
    setTotalFilesUploaded((prev) => prev + 1);
    await uploadNextFile(index + 1, idexamen);
  };

  //se sube archivo por archivo al bucket
  const uploadFile = async (fileOrBlob, index, idexamen) => {
    const formData = new FormData();

    if (fileOrBlob.blob instanceof Blob) {
      const originalname = "nuevo_archivo.mp4";
      formData.append("file", fileOrBlob.blob, originalname);
    } else if (fileOrBlob instanceof File) {
      formData.append("file", fileOrBlob);
    }

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadedProgress((prevState) => ({
          ...prevState,
          [index]: percentCompleted,
        }));
      },
    };

    try {
      const url = `/examen/upload/${idexamen}`;
      await lanekApi.post(url, formData, config);
    } catch (error) {
      setIsUploading(false);
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const normalizeFiles = (files) => {
    return files
      .map((file) => {
        if (file instanceof File) {
          return file;
        } else if (file.blob instanceof Blob && file.name) {
          return new File([file.blob], file.name);
        }
        return null;
      })
      .filter(Boolean);
  };

  const createExam = async (idexamen, producto, categoria, { email, id }) => {
    await handleUploadFilesToBucket(idexamen);

    const formData = new FormData();
    const normalizedFiles = normalizeFiles(state.archivos);
    normalizedFiles.forEach((element) => {
      formData.append("files", element);
    });

    let module;
    let sqs;
    if (producto === "avm") {
      module = "avm-processing-module";
      sqs = "avm-processing-queue";
    } else if (producto === "abma") {
      module = "abma-lite-processing-module";
      sqs = "abma-lite-processing-queue";
    } else {
      module = "maker-processing-module";
      sqs = "maker-processing-queue";
    }

    const autor = usuario.paciente;
    const nombre_autor = autor.apellidos
      ? autor.nombre + " " + autor.apellidos
      : autor.nombre;

    const data = {
      autor: nombre_autor,
      idexamen,
      producto,
      categoria,
      email,
      module,
      sqs,
      stage: stageForWorker,
      url: urlForWorker,
      id_lugarAtencion: "c457d591-3a34-4647-9e90-2e4d6e172095",
    };

    formData.append("info", JSON.stringify(data));

    try {
      await lanekApi.post(`/examen/${id}/nuevo-examen`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      getPatientExams(id);
      setFilesList([]);
      setFilesBlobs([]);
      setTotalFilesUploaded(0);
      setUploadedPercent({});

      toast({
        description: "Examen creado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };
  const getPatientExams = async (idpaciente) => {
    const url = `/paciente/${idpaciente}/examenes`;
    try {
      const { data } = await lanekApi.get(url);
      dispatch({
        type: "CARGAR_EXAMENES_PACIENTE",
        payload: data.examenes,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <ExamenContext.Provider
      value={{
        ...state,
        isUploading,
        uploadedFiles: filesList,
        uploadedPercent,
        totalFilesUploaded,
        getPatientExams,
        createExam,
        setUploadedFilesList,
        setUploadedPercent,
        setFilesBlobs,
        handleNewVideoFile,
        handleUploadFilesToBucket,
      }}
    >
      {children}
    </ExamenContext.Provider>
  );
};

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "../../../src/assets/css/App.css"; 
import { Flex, useBreakpointValue } from "@chakra-ui/react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

export const AllLineChart = ({ fullData, dataExamType }) => {   
  const titleVariant = useBreakpointValue({
    base: 18,
    md: 24,
  });

  const heightVariant = useBreakpointValue({
    base: 500,
    md: 500,
  });

  const widthVariant = useBreakpointValue({
    base: 600,
    md: 500,
  });

  if(dataExamType === "avm"){
    let labels = Array.from({ length: 567 }, (_, index) => index * 1);
  const tiempoEtiquetas = labels.filter((index) => index % 1 === 0);  

  return (
    <>
      {fullData.map((datosDiagrama, index) => (
        <Flex
          borderRadius="md"
          boxShadow="md"
          borderWidth={1}
          height="object-fit"
          justify="center"
          mb={4}
          p={{ base: 0, md: 6 }}
          w="full"
        >
          <Line
            key={index}
            width={widthVariant}
            height={heightVariant}
            data={{
              labels: tiempoEtiquetas,
              datasets: [
                {
                  label: datosDiagrama.feature,
                  data: datosDiagrama.data,
                  fill: false,
                  borderColor: "rgba(75,192,192,1)",
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: true,
                  text: datosDiagrama.title,
                  font: {
                    size: titleVariant,
                    fontWeight: "bold",
                  },
                },
                zoom: {
                  pan: {
                    enabled: true,
                    mode: "xy",
                  },
                },
              },
              scales: {
                x: {
                  type: "linear", 
                  ticks: {
                    stepSize: 100, 
                  },
                  display: true,
                  title: {
                    display: true,
                    text: datosDiagrama?.xaxis + ` [${datosDiagrama?.xunit}]`,
                  },
                },
                y: {
                  type: "linear", 
                  display: true,
                  title: {
                    display: true,
                    text: datosDiagrama?.yaxis + ` [${datosDiagrama?.yunit}]`,
                  },
                },
              },
            }}
          />
        </Flex>
      ))}
    </>
  );
  }
  if(dataExamType === "abma-lite"){  

  return (
    <>    
      {fullData.map((datosDiagrama, index) => (        
        datosDiagrama.data.length > 1 ? (<Flex
          borderRadius="md"
          boxShadow="md"
          height="object-fit"
          justify="center"
          mb={4}
          p={{ base: 0, md: 6 }}
          w="full"
        >          
          <Line
            key={index}
            width={widthVariant}
            height={heightVariant}
            data={{
              labels: datosDiagrama.time,
              datasets: [
                {
                  label: datosDiagrama.feature,
                  data: datosDiagrama.data,
                  fill: false,
                  borderColor: "rgba(75,192,192,1)",
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: true,
                  text: datosDiagrama.title,
                  font: {
                    size: titleVariant,
                    fontWeight: "bold",
                  },
                },
                zoom: {
                  pan: {
                    enabled: true,
                    mode: "xy",
                  },
                },
              },
              scales: {
                x: {
                  type: "linear", 
                  ticks: {
                    stepSize: 100, 
                  },
                  display: true,
                  title: {
                    display: true,
                    text: datosDiagrama?.xaxis + ` [${datosDiagrama?.xunit}]`,
                  },
                },
                y: {
                  type: "linear", 
                  display: true,
                  title: {
                    display: true,
                    text: datosDiagrama?.yaxis + ` [${datosDiagrama?.yunit}]`,
                  },
                },
              },
            }}
          />
        </Flex>): null        
      ))}
    </>
  );
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Button,
  Icon,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";
import {
  FaRunning,
  FaMicrophoneAlt,
  FaPhotoVideo,
  FaEllipsisH,
} from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import { lanekApi } from "../api/lanekApi";
import { ExamOptionCard } from "../components/ExamOptionCard";

const productos = [
  {
    id: 1,
    nombre: "abma",
    descripcion:
      "Puedes subir videos de evaluaciones de análisis del movimiento utilizando sistema ABMA.",
    icono: FaRunning,
    is_habilitado: true,
  },
  {
    id: 2,
    nombre: "avm",
    descripcion:
      "Puedes subir archivos de audio obtenidos del dispositivo AVM.",
    icono: FaMicrophoneAlt,
    is_habilitado: true,
  },
  {
    id: 3,
    nombre: "video",
    descripcion:
      "Puedes subir video para análisis de biomarcadores digitales de imágenes y audio.",
    icono: FaPhotoVideo,
    is_habilitado: true,
  },
  {
    id: 4,
    nombre: "otro",
    descripcion: "Puedes subir otros archivos o exámenes para ser procesados.",
    icono: FaEllipsisH,
    is_habilitado: false,
  },
];

export const ExamsOptionsScreen = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setisLoading] = useState(false);
  const [productoSeleccionado, setProductoSeleccionado] = useState("");

  const cancelRef = React.useRef();

  const handleSelectedExam = (e) => {
    setProductoSeleccionado(e.target.value);
    onOpen();
  };

  const handleCreate = async () => {
    let idexamen;
    setisLoading(true);
    await lanekApi
      .post("/examen")
      .then(({ data }) => (idexamen = data.item.id));
    navigate(`/mis_examenes/nuevo_examen/${productoSeleccionado}/${idexamen}`, {
      replace: true,
    });
    setisLoading(false);
    onClose();
  };

  return (
    <>
      <Box maxW="7xl" mx={"auto"}>
        <SimpleGrid columns={{ base: 2, lg: 3 }} spacing={{ base: 5, lg: 8 }}>
          {productos.map(
            ({ nombre, descripcion, is_habilitado, id, icono }) => (
              <ExamOptionCard
                key={id}
                heading={nombre}
                icon={<Icon as={icono} w={"2.5rem"} h={"2.5rem"} />}
                description={descripcion}
                disable={!is_habilitado}
                value={nombre}
                handle={handleSelectedExam}
              />
            )
          )}
        </SimpleGrid>
      </Box>

      {productoSeleccionado && (
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent p={4}>
              <AlertDialogBody>
                Estás seguro de continuar? No podrás volver atrás.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button isLoading={isLoading} colorScheme="twitter" onClick={handleCreate} mr={3}>
                  Confirmar
                </Button>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancelar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};

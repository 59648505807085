import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { InputNormal } from "./InputForm";
import { ButtonWithSpinner } from "../CustomButtons";

const validationSchema = Yup.object({
  nombre: Yup.string().required("Campo obligatorio"),
  apellidos: Yup.string().required("Campo obligatorio"),
  email: Yup.string()
    .email("Correo electrónico inválido")
    .required("Campo obligatorio"),
});

export const RegisterForm = ({ initialValues, onSubmit, isLoading }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, values, touched, handleBlur, handleChange }) => (
        <Form>
          <Flex minH={"100vh"} align={"center"} justify={"center"}>
            <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12}>
              <Stack align={"center"}>
                <Heading fontSize={"4xl"} textAlign={"center"}>
                  Regístrate en Lanek
                </Heading>
              </Stack>

              <Box rounded={"lg"} boxShadow={"lg"} p={8}>
                <Stack spacing={4}>
                  <HStack>
                    <Box>
                      <InputNormal
                        isRequired
                        label="Nombre"
                        name="nombre"
                        type="text"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        value={values.nombre}
                      />
                      {touched.nombre && errors.nombre ? (
                        <Text as={"p"} mt={2}>
                          {errors.nombre}
                        </Text>
                      ) : null}
                    </Box>
                    <Box>
                      <InputNormal
                        isRequired
                        label="Apellidos"
                        name="apellidos"
                        type="text"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        value={values.apellidos}
                      />
                      {touched.apellidos && errors.apellidos ? (
                        <Text as={"p"} mt={2}>
                          {errors.apellidos}
                        </Text>
                      ) : null}
                    </Box>
                  </HStack>

                  <Box>
                    <InputNormal
                      isRequired
                      label="Correo electrónico"
                      name="email"
                      type="email"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values.email}
                    />
                    {touched.email && errors.email ? (
                      <Text as={"p"} mt={2}>
                        {errors.email}
                      </Text>
                    ) : null}
                  </Box>

                  <FormControl id="contraseña" isRequired>
                    <FormLabel>Contraseña</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={handleChange}
                        name="contraseña"
                        value={values.contraseña}
                        type={showPassword ? "text" : "password"}
                      />
                      <InputRightElement h={"full"}>
                        <Button
                          variant={"ghost"}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                        >
                          {showPassword ? (
                            <Icon as={FaEyeSlash} />
                          ) : (
                            <Icon as={FaEye} />
                          )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <Stack pt={2}>
                    <ButtonWithSpinner
                      titleButton="Registrar"
                      isLoading={isLoading}
                    />
                  </Stack>

                  <Stack mt={15}>
                    <Text>
                      ¿Ya tienes una cuenta?{" "}
                      <Link
                        color="blue"
                        onClick={() => navigate("/auth/login")}
                      >
                        Inicia sesión
                      </Link>
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

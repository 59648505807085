/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  ScaleFade,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ProfilePassword } from "./ProfilePassword";
import { ProfileInfoUser } from "./ProfileInfoUser";
import { StoreContext } from "../context/store/StoreContext";
import { RenderLoading } from "./RenderAlert";
import AuthContext from "../context/auth/AuthContext";

export const ProfileContent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { usuario } = useContext(AuthContext);
  const { getPatient, paciente } = useContext(StoreContext);

  const fullname = paciente.apellidos
    ? `${paciente?.nombre} ${paciente?.apellidos}`
    : `${paciente?.nombre}`;

  const fetchPatientData = useCallback(async () => {
    setIsLoading(true);
    await getPatient(usuario.id_paciente);
    setIsLoading(false);
  }, [usuario.id]);

  useEffect(() => {
    fetchPatientData();
  }, [fetchPatientData]);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in>
          <Card
            boxShadow={"md"}
            borderWidth="1px"
            borderColor="gray.200"
            bg={"white.300"}
            rounded={"lg"}
          >
            <CardBody>
              <Box>
                <Flex justify="center" align="center">
                  <Avatar boxSize={"5rem"} bg="teal.500" mr={4} />
                  <VStack align={"flex-start"}>
                    <Heading as="h2" size={"xl"} color={"gray.600"}>
                      {fullname}
                    </Heading>
                    <Text>{paciente?.email}</Text>
                  </VStack>
                </Flex>

                <Flex direction={{ base: "column", md: "row" }}>
                  <ProfileInfoUser user={paciente} />

                  <ProfilePassword user={usuario} />
                </Flex>
              </Box>
            </CardBody>
          </Card>
        </ScaleFade>
      )}
    </>
  );
};

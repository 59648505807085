import React from "react";
import { Box, Button, Center, Flex, Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaRegCheckCircle } from "react-icons/fa";

export const EmailSent = () => {
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    navigate("/auth/login", { replace: true });
  };

  return (
    <>
      <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
        <Flex p={8} flex={1} align={"center"} justify={"center"}>
          <Stack mx={"auto"} w={"md"} maxW={"lg"} py={12} px={6}>
            <Box rounded={"lg"} boxShadow={"lg"} p={8}>
              <Stack spacing={4}>
                <FaRegCheckCircle size={36} color="#46e53e" />
                <Center>
                <Text fontSize="xl" fontWeight="bold" mt={4}>
                  Correo enviado!!
                </Text>
                </Center>
                <Text fontSize="lg" mt={2}>
                  Por favor, revise su correo para el cambio de su
                  contraseña.
                </Text>
                <Button
                  onClick={handleResetPassword}                  
                >
                  INICIO
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </Stack>
    </>
  );
};

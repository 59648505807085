import React from 'react';
import { View, Image, } from '@react-pdf/renderer';
import { styles } from "../../../utils/stylesDocumentPdf";
import LogoLanek from "../../../assets/img/logo.png";

const HeaderReport = ({ info }) => (      
  <>
  <View style={styles.tableWhiteRow} fixed>
            <View style={styles.tableColWhite}>
              <Image style={styles.image} src={LogoLanek} fixed/>          
            </View>
            {/* <View style={styles.tableColWhite}>              
            <Text style={styles.titleBordePage} fixed>{info.AVM.header.titleBordePage}</Text>      
            </View> */}
  </View>    
  </>
);

export default HeaderReport;
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/AuthContext";
import { RegisterForm } from "../../components/Forms/RegisterForm";

const initialValues = {
  nombre: "",
  apellidos: "",
  email: "",
  contraseña: "",
  rut: "",
};

const RegistroScreen = () => {
  const navigate = useNavigate();

  const { isLoading, startRegister } = useContext(AuthContext);

  const onSubmit = async (values) => {
    const request = await startRegister(values);
    if (request) {
      navigate("/perfil");
    }
  };

  return (
    <>
      <RegisterForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </>
  );
};

export default RegistroScreen;

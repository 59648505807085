import React, { useContext, useState } from "react";
import {
  CircularProgress,
  CircularProgressLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import { FileUploadOptionsBox } from "../../components/FileUploadOptionsBox";
import { FaFileVideo, FaPlay, FaTrash } from "react-icons/fa6";
import { ExamenContext } from "../../context/examen/ExamenContext";
import { ButtonNormal } from "../../components/CustomButtons";

const VideoPreviewModal = ({ isOpen, onClose, videoPreview }) => {
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Previsualizacion del video</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {videoPreview instanceof Blob && (
            <video
              src={URL.createObjectURL(videoPreview)}
              controls
              style={{ width: "100%" }}
            />
          )}
          {videoPreview === null && <p>El video no está disponible.</p>}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const RenderFilesTable = ({
  uploadedFiles,
  uploadedPercent,
  archivos,
  videoPreview,
  setVideoPreview,
  handleDeleteFile,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  if (uploadedFiles.length === 0) return null;

  const handleVideoPreview = (videoUrl) => {
    setVideoPreview(videoUrl);
    onOpen();
  };

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>Nombre archivo</Th>
            <Th>Tamaño (MB)</Th>
            <Th>Progreso</Th>
            <Th>Acciones</Th>
          </Tr>
        </Thead>
        <Tbody>
          {uploadedFiles.map((file, index) => (
            <Tr key={index}>
              <Td>{file.name}</Td>
              <Td>
                {file.blob
                  ? (file.blob.size / (1024 * 1024)).toFixed(2)
                  : "N/A"}
              </Td>
              <Td>
                {
                  <CircularProgress
                    color="green.400"
                    size={"3rem"}
                    value={uploadedPercent[index] || 0}
                  >
                    <CircularProgressLabel>
                      {uploadedPercent[index] || 0}%
                    </CircularProgressLabel>
                  </CircularProgress>
                }
              </Td>
              <Td>
                <Tooltip hasArrow label="Previsualizar">
                  <IconButton
                    onClick={() => handleVideoPreview(archivos[index])}
                    size="md"
                    colorScheme="twitter"
                    mr={2}
                    variant="solid"
                    aria-label="previsualizar video"
                    icon={<FaPlay />}
                  />
                </Tooltip>

                <Tooltip hasArrow label="Eliminar archivo">
                  <IconButton
                    onClick={() => handleDeleteFile(index)}
                    size="md"
                    variant="ghost"
                    aria-label="eliminar archivo"
                    icon={<FaTrash />}
                  />
                </Tooltip>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <VideoPreviewModal
        isOpen={isOpen}
        onClose={onClose}
        videoPreview={videoPreview}
      />
    </>
  );
};

export const AbmaScreen = ({ handleCreate }) => {
  const {
    archivosBlob,
    uploadedFiles,
    uploadedPercent,
    setUploadedFilesList,
    setUploadedPercent,
    setFilesBlobs,
  } = useContext(ExamenContext);
  const [archivos, setArchivos] = useState([]);
  const [videoPreview, setVideoPreview] = useState();

  const handleDeleteFile = (index) => {
    // Elimina el archivo del estado uploadedFiles
    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles.splice(index, 1);
    setUploadedFilesList(newUploadedFiles);

    // Elimina el archivo del estado archivosBlob
    const newArchivosBlob = [...archivosBlob];
    newArchivosBlob.splice(index, 1);
    setFilesBlobs(newArchivosBlob);

    // También debes eliminar el progreso del archivo eliminado si lo deseas
    const newUploadedPercent = { ...uploadedPercent };
    delete newUploadedPercent[index];
    setUploadedPercent(newUploadedPercent);
  };

  return (
    <>
      <FileUploadOptionsBox
        archivos={archivos}
        setArchivos={setArchivos}
        setVideoPreview={setVideoPreview}
        placeHolder={"Seleccionar archivos (.mp4, .mov)"}
        accept={"video/*"}
        icon={FaFileVideo}
      />

      {uploadedFiles.length >= 1 && (
        <>
          <Text as={"h2"} fontWeight={"bold"}>
            Archivos seleccionados: {uploadedFiles.length}
          </Text>
          <RenderFilesTable
            uploadedFiles={uploadedFiles}
            uploadedPercent={uploadedPercent}
            setUploadedFilesList={setUploadedFilesList}
            setUploadedPercent={setUploadedPercent}
            archivos={archivosBlob}
            videoPreview={videoPreview}
            setVideoPreview={setVideoPreview}
            setArchivos={setArchivos}
            handleDeleteFile={handleDeleteFile}
          />

          <ButtonNormal fn={handleCreate} titleButton="Finalizar examen" />
        </>
      )}
    </>
  );
};
import { Box, Flex } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { lanekApi } from "../../api/lanekApi";
import { ButtonWithIcon } from "../CustomButtons";
import { FaReply } from "react-icons/fa";
import { DetallePdf } from "./DetallePdf";
import { RenderLoading } from "../RenderAlert";
import AuthContext from "../../context/auth/AuthContext";
import { Graficos } from "./Graficos";
import { SessionManager } from "../SessionManager";

export const DetalleExamen = ({ setOpenDetalle }) => {
  const { usuario } = useContext(AuthContext);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState();
  const [dataPaciente, setPaciente] = useState([]);
  const [dataExamType, setExamType] = useState([]);

  const idexamen = sessionStorage.getItem("examen");

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const { data } = await lanekApi.get(`/examen/images/${idexamen}/all`);
        const imagesFiltered = data.imagesList.filter((img) =>
          img.key.includes(".png")
        );
        setImages(imagesFiltered);

        const { data: dataPaciente } = await lanekApi.get(
          `/paciente/${usuario.id_paciente}`
        );
        setPaciente(dataPaciente.item);

        const { data: dataJson } = await lanekApi.get(
          `/examen/json-file/${idexamen}`
        );
        const dataJSON = JSON.parse(dataJson.jsonData);        
        if (dataJSON.exam_type){          
          setExamType(dataJSON.exam_type);
          setData(dataJSON);
        }
        else{
          if (dataJSON.patient.exam_type === "avm") {            
            setExamType(dataJSON.patient.exam_type);
            setData(dataJSON);
          } else {
            setExamType(dataJSON.exam_type);
            setData(dataJSON);
          }
        }

        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchImages();
  }, [idexamen, usuario.id_paciente]);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <>
        <SessionManager />
        <Box>
          <Flex justify={"flex-start"} align={"flex-end"} mb={4}>
            <ButtonWithIcon
              fn={setOpenDetalle}
              titleIcon={FaReply}
              titleButton={"Volver"}
              colorScheme="cyan"
              color={"white"}
            />
            <DetallePdf
              images={images}
              data={data}
              dataPaciente={dataPaciente}
              dataExamType={dataExamType}
            />            
          </Flex>

          <Graficos
            datos={data}
            dataExamType={dataExamType}
            imagenes={images}
          />
        </Box>
        </>
      )}
    </>
  );
};

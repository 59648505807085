import React from "react";
import AppRouter from "./routers/AppRouter";

const LanekApp = () => {
  return (
    <>
      <AppRouter />
    </>
  );
};

export default LanekApp;

import React from 'react';
import { Text } from '@react-pdf/renderer';
import { styles } from "../../../utils/stylesDocumentPdf";

const ReportFooter = () => (
    <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
);

export default ReportFooter;
import { Box, Heading, Container, Text, Button, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const HeroSection = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("mis_examenes");
  };
  return (
    <>
      <Container maxW={"full"} bg={"white"} rounded={"lg"} shadow={"md"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={10}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "5xl" }}
            lineHeight={"110%"}
            color={"gray.600"}
          >
            ¡Bienvenidos a nuestra plataforma <br />
            <Text as={"span"} color={"cyan.400"}>
              dedicada al cuidado de la salud!
            </Text>
          </Heading>
          <Text color={"gray.500"}>
            Descubre el potencial de tu voz y movimiento corporal con nuestros
            exámenes especializados. ¡Explora una nueva dimensión de bienestar
            personal y profesional!"
          </Text>
          <Stack
            direction={"column"}
            spacing={3}
            align={"center"}
            alignSelf={"center"}
            position={"relative"}
          >
            <Button
              colorScheme={"green"}
              bg={"cyan.400"}
              rounded={"full"}
              px={6}
              onClick={handleNavigate}
              _hover={{
                bg: "cyan.500",
              }}
            >
              Comienza ahora
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaSignOutAlt, FaUserAlt, FaAngleDown, FaBars } from "react-icons/fa";
import AuthContext from "../../context/auth/AuthContext";
import { FeedbackModal } from "../Modals/FeedbackModal";

export const MobileNav = ({ onOpen, ...rest }) => {
  const navigate = useNavigate();
  const { usuario, rol, logoutRequest } = useContext(AuthContext);

  const user = usuario.paciente;

  const nombre_usuario = user.apellidos
    ? user.nombre + " " + user.apellidos
    : user.nombre;

  const handleGoHome = () => {
    navigate("/");
  };

  const handleLogout = () => {
    logoutRequest();
    navigate("/auth/login", {
      replace: true,
    });
  };
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FaBars />}
      />

      <Box
        display={{ base: "flex", md: "none" }}
        className="bg__login--img"
        onClick={handleGoHome}
        _hover={{ cursor: "pointer" }}
      />

      <HStack spacing={{ base: "0", md: "6" }}>
        <FeedbackModal />

        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size="sm" bg="green.400" name={nombre_usuario} />

                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text as={"b"} fontSize="sm">
                    {nombre_usuario}
                  </Text>
                  <Text fontSize="xs" color="gray.600">
                    {rol.nombre}
                  </Text>
                </VStack>

                <Box display={{ base: "none", md: "flex" }}>
                  <FaAngleDown />
                </Box>
              </HStack>
            </MenuButton>

            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem
                icon={<FaUserAlt />}
                onClick={() => navigate("/perfil")}
              >
                Perfil
              </MenuItem>

              <MenuDivider />
              <MenuItem icon={<FaSignOutAlt />} onClick={handleLogout}>
                Cerrar sesión
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
